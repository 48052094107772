<template>
  <div class="interview-container">
    <div class="left">
      <div class="video-container" :class="{ 'is-float': isScreenShare, 'float-window': isScreenShare }" id="remote_stream"></div>
      <div class="float-window" id="local_stream"></div>
      <div class="video-container" :style="{ display: isScreenShare ? 'block' : 'none' }" id="share_stream"></div>
      <div class="tools">
        <ul>
          <li :class="cameraEnum[cameraStatus].class" @click="handleCamera">
            <YTIcon :href="cameraEnum[cameraStatus].icon" @click="handleCamera"></YTIcon>
          </li>
          <li :class="callStatus ? 'call-end' : 'call-start'" @click="handleLocalStream">
            <YTIcon :href="callStatus ? '#icon-guaduan' : '#icon-tonghua'" @click="handleLocalStream"></YTIcon>
          </li>
          <li :class="micEnum[micStatus].class" @click="handleMic">
            <YTIcon :href="micEnum[micStatus].icon" @click="handleMic"></YTIcon>
          </li>
        </ul>
      </div>
    </div>
    <div class="right">
      <ul class="message-list">
        <li v-for="(message, index) in messageList" :key="index">
          <template v-if="message.type === 1">
            <div class="tips">{{ message.text }}</div>
          </template>
          <template v-else>
            <div class="real-name">{{ message.name }}:</div>
            <div class="bubble">{{ message.text }}</div>
          </template>
        </li>
      </ul>
      <div class="input-block">
        <el-input v-model="messageData" @keydown.enter.native="sendMessage">
          <div slot="append" class="send" @click="sendMessage">发送</div>
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
import TRTC from 'trtc-js-sdk'
import TIM from 'tim-js-sdk'
import YTIcon from '@components/common/YTIcon'
export default {
  name: 'VideoInterview',
  components: { YTIcon },
  data() {
    return {
      trtcData: {},
      client: null,
      localStream: null, //本地流
      localStreamStandBy: false, //是否支持推流
      callStatus: false, //是否通话中
      cameraStatus: 'on', //摄像头状态
      cameraEnum: {
        on: {
          icon: '#icon-kaiqishexiangtou',
          class: 'on',
          method: this.cameraOn
        },
        off: {
          icon: '#icon-guanbishexiangtou',
          class: 'off',
          method: this.cameraOff
        }
      },
      micStatus: 'on', //麦克风状态
      micEnum: {
        on: {
          icon: '#icon-kaiqimaikefeng',
          class: 'on',
          method: this.micOn
        },
        off: {
          icon: '#icon-guanbimaikefeng',
          class: 'off',
          method: this.micOff
        }
      },
      tim: null, //IM聊天
      messageClient: null,
      messageData: '',
      messageList: [], //消息列表
      isScreenShare: false //是否开始屏幕分享
    }
  },
  mounted() {
    this.trtcData = JSON.parse(sessionStorage.getItem('VideoInterviewData'))
    this.initClient()
    this.initTim()
  },
  beforeDestroy() {
    this.client
      .leave()
      .then(() => {
        // 退房成功，可再次调用client.join重新进房开启新的通话。
      })
      .catch(error => {
        // console.error('退房失败 ' + error)
        // 错误不可恢复，需要刷新页面。
      })
  },
  methods: {
    initClient() {
      //创建client
      this.client = TRTC.createClient({
        mode: 'rtc',
        sdkAppId: parseInt(this.trtcData.sdkAppId),
        userId: this.trtcData.userId,
        userSig: this.trtcData.userSig,
        useStringRoomId: true
      })

      TRTC.checkSystemRequirements().then(checkResult => {
        // 环境检测
        if (checkResult.result) {
          // 支持进房
          if (checkResult.detail.isH264DecodeSupported) {
            // 支持拉流
            this.initRemoteStream()
          }
          if (checkResult.detail.isH264EncodeSupported) {
            // 支持推流
            //进入房间
            this.client
              .join({ roomId: this.trtcData.roomId })
              .then(() => {
                // console.log('进房成功')
                this.initLocalStream()
              })
              .catch(error => {
                // console.error('进房失败 ' + error)
              })
          }
        }
      })
    },
    initRemoteStream() {
      //初始化远端流
      this.client.on('stream-added', event => {
        const remoteStream = event.stream
        // console.log('远端流增加: ' + remoteStream.getId())
        //订阅远端流
        this.client.subscribe(remoteStream)
      })
      this.client.on('stream-subscribed', event => {
        const remoteStream = event.stream
        // console.log(event)
        if (event.stream.userId_.includes('share')) {
          this.isScreenShare = true
          remoteStream.play('share_stream')
        } else {
          // 播放远端流
          remoteStream.play('remote_stream')
        }
        // console.log('远端流订阅成功：' + remoteStream.getId())
      })
      this.client.on('stream-removed', event => {
        const remoteStream = event.stream
        // console.log('远端流增加: ' + remoteStream.getId())
        //订阅远端流
        // console.log(event)
        if (event.stream.userId_.includes('share')) {
          this.isScreenShare = false
        }
        // this.client.subscribe(remoteStream)
      })
    },
    async initLocalStream() {
      //初始化本地流
      try {
        this.localStream = TRTC.createStream({ userId: this.trtcData.userId, audio: true, video: true })
        await this.localStream.initialize()
        this.localStream.play('local_stream')
        // console.log('初始化本地流成功')
        try {
          // console.log('本地流发布成功')
          this.localStreamStandBy = true
        } catch (error) {
          // console.error('本地流发布失败 ' + error)
        }
      } catch (error) {
        // console.error('初始化本地流失败 ' + error)
        switch (error.name) {
          case 'NotReadableError':
            alert('暂时无法访问摄像头/麦克风，请确保系统允许当前浏览器访问摄像头/麦克风，并且没有其他应用占用摄像头/麦克风')
            return
          case 'NotAllowedError':
            if (error.message === 'Permission denied by system') {
              alert('请确保系统允许当前浏览器访问摄像头/麦克风')
            } else {
              // console.log('User refused to share the screen')
            }
            return
          case 'NotFoundError':
            alert('浏览器获取不到摄像头/麦克风设备，请检查设备连接并且确保系统允许当前浏览器访问摄像头/麦克风')
            return
          default:
            return
        }
      }
    },
    handleLocalStream() {
      //是否推送本地流
      if (this.localStreamStandBy && !this.callStatus) {
        this.client.publish(this.localStream)
      } else if (this.callStatus) {
        this.client.unpublish(this.localStream)
      }
      this.callStatus = !this.callStatus
    },
    handleCamera() {
      // 控制摄像头
      this.cameraStatus = this.cameraStatus === 'on' ? 'off' : 'on'
      this.cameraEnum[this.cameraStatus].method()
    },
    cameraOn() {
      // 打开视频，远端会收到 'unmute-video' 事件
      this.localStream.unmuteVideo()
    },
    cameraOff() {
      // 关闭视频，远端会收到 'mute-video' 事件
      this.localStream.muteVideo()
    },
    handleMic() {
      // 控制麦克风
      this.micStatus = this.micStatus === 'on' ? 'off' : 'on'
      this.micEnum[this.micStatus].method()
    },
    micOn() {
      // 打开音频，远端会收到 'unmute-audio' 事件
      this.localStream.unmuteAudio()
    },
    micOff() {
      // 关闭音频，远端会收到 'mute-audio' 事件
      this.localStream.muteAudio()
    },
    initTim() {
      //初始化IM
      this.tim = TIM.create({
        SDKAppID: parseInt(this.trtcData.sdkAppId)
      })
      this.tim.setLogLevel(0) //0调试, 1生产
      this.tim.login({
        userID: this.trtcData.userId,
        userSig: this.trtcData.userSig
      })
      this.tim.on(TIM.EVENT.MESSAGE_RECEIVED, event => {
        // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
        // event.name - TIM.EVENT.MESSAGE_RECEIVED
        // event.data - 存储 Message 对象的数组 - [Message]
        this.getHistoryList()
        // console.log(event)
      })
      this.tim.on(TIM.EVENT.SDK_READY, this.handleSDKReady)
    },
    handleSDKReady(event) {
      //搜索群组是否存在
      let promise = this.tim.searchGroupByID(this.trtcData.roomId)
      promise
        .then(imResponse => {
          //获取历史消息
          this.joinGroup()
          this.getHistoryList()
        })
        .catch(imError => {
          this.createGroup()
          // 搜素群组失败的相关信息
        })
    },
    createGroup() {
      //创建群组
      this.tim
        .createGroup({
          name: `yt${this.trtcData.realName}`,
          type: TIM.TYPES.GRP_MEETING,
          groupID: this.trtcData.roomId
        })
        .then(imResponse => {
          this.joinGroup()
        })
    },
    joinGroup() {
      //加入群组
      this.tim
        .joinGroup({ groupID: this.trtcData.roomId, type: TIM.TYPES.GRP_MEETING })
        .then(imResponse => {
          this.setNameCard()
        })
        .catch(imError => {
          // 申请加群失败的相关信息
          // console.log(imError)
          // this.$message.error('加入聊天失败, 请稍后再试')
        })
    },
    setNameCard() {
      //设置群名片
      this.tim.setGroupMemberNameCard({
        groupID: this.trtcData.roomId,
        nameCard: this.trtcData.realName
      })
    },
    getHistoryList() {
      //获取历史消息
      this.tim.getMessageList({ conversationID: `GROUP${this.trtcData.roomId}`, count: 15 }).then(imResponse => {
        this.messageList = imResponse.data.messageList.map(item => {
          // console.log(item)
          let result = {}
          if (item.payload.hasOwnProperty('data') && item.payload.data === 'group_create') {
            result.type = 1
            result.text = `${item.nameCard}创建群组`
          } else {
            result.type = 0
            result.text = item.payload.text
            result.time = this.$formatTime(item.time, 'yyyy-MM-dd hh-mm')
            result.name = item.nameCard
          }
          return result
        })
      })
    },
    sendMessage() {
      //创建消息实例
      let messageClient = this.tim.createTextMessage({
        to: this.trtcData.roomId,
        conversationType: TIM.TYPES.CONV_GROUP,
        payload: {
          text: this.messageData
        }
      })
      // 2. 发送消息
      let promise = this.tim.sendMessage(messageClient)
      promise
        .then(imResponse => {
          // 发送成功
          this.getHistoryList()
          this.messageData = ''
        })
        .catch(imError => {
          // 发送失败
          // console.warn('sendMessage error:', imError)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.interview-container {
  display: flex;
  height: 100%;
  .left {
    width: calc(100% - 300px);
    height: 100%;
    position: relative;
    .video-container {
      height: calc(100% - 100px);
      background-color: #000;
      &.is-float {
        top: 180px;
      }
    }
    .float-window {
      width: 300px;
      height: 180px;
      z-index: 999;
      position: absolute;
      top: 0;
      right: 0;
    }
    .tools {
      .flexStyle();
      height: 100px;
      background-color: #2a334a;
      ul {
        .flexStyle();
        li {
          .flexStyle();
          width: 120px;
          height: 45px;
          margin: 0 20px;
          padding: 0 15px;
          color: #fff;
          border-radius: 25px;
          cursor: pointer;
          &.call-start {
            background-color: #07db93;
          }
          &.call-end {
            background-color: #ff1a2b;
          }
          &.on {
            background-color: #448bff;
          }
          &.off {
            background-color: #999999;
          }
          svg {
            font-size: 25px;
          }
        }
      }
    }
  }
  .right {
    width: 300px;
    background-color: #2a334a;
    .message-list {
      height: calc(100% - 100px);
      overflow-y: auto;
      li {
        font-size: 18px;
        overflow: hidden;
        div {
          margin: 10px;
          color: #f0f0f0;
        }
        .real-name {
          text-align: left;
        }
        .bubble {
          padding: 0 10px;
          background-color: #448bff;
          border-radius: 12px;
          text-align: left;
        }
        .tips {
          display: inline-block;
          padding: 0 10px;
          background-color: #666666;
          font-size: 14px;
          text-align: center;
          border-radius: 12px;
        }
      }
    }
    .input-block {
      .flexStyle();
      height: 100px;
      padding: 0 20px;
      background-color: #354061;
      ::v-deep .el-input__inner {
        border-color: #448bff;
      }
      ::v-deep .el-input-group__append {
        color: #fff;
        border-color: #448bff;
        background-color: #448bff;
      }
    }
  }
}
</style>
